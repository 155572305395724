import React from "react"
import GrantForm from "../components/form/grantForm"
import SEO from "../components/seo"
import ourServices from "../components/ourServices/our-services"
import FormAudit from "../components/formAudit/formAudit"
import ReviewsExtended from "../components/reviews/reviewsExtended"
import { graphql, Link } from "gatsby"
import Triangle from "../components/triangle/triangle"
import Shape from "../components/shape/shape"
import CaseStudyTeaserImage from "../components/caseStudyTeaserImage/caseStudyTeaserImage"

const slugify = require("slugify")

const ReferralConcreteGrantPage = ({ data: { page }, location }) => { 
  return (
    <>
      <SEO
        titleOverride={page.metaTags && page.metaTags.title ? page.metaTags.title : page.title}
        descriptionOverride={page.metaTags && page.metaTags.description ? page.metaTags.description : null}
        pathnameOverride={location.pathname}
        imageOverride={page.metaTags && page.metaTags.image ? page.metaTags.image.url : null}
      />

      <section id="form" className="mt-16 mb-16 lg:mt-0 md:mb-20 xl:mb-24">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full pt-0 pr-6 mb-12 lg:w-7/12 content lg:mb-0 lg:pl-12 xl:pl-24 lg:pr-16 xl:pr-24 lg:pt-24 xl:pt-32">
              <div className="mb-8 lg:mb-12">
                <div className="">
                  <h1 className="h1--large xl:pr-24">{ page.heroHeading }</h1>
									<div dangerouslySetInnerHTML={{ __html: page.heroBlurb }} className="content content--reset"></div>
                </div>
              </div>

              
            </div>
            
            <div className="w-full p-8 py-12 ml-auto lg:w-5/12 bg-secondary md:p-12 md:py-16 xl:py-24">
              <div className="flex flex-wrap items-center justify-center h-full">
                <GrantForm />
              </div>
            </div>
          </div>
        </div>
      </section>

	    {/* Reviews */}
      <section className="pb-12 mb-12 border-b border-grey md:pb-16 xl:pb-20 md:mb-16 xl:mb-20">
        <div className="container">
          <ReviewsExtended />
        </div>
      </section>

      {/* Services */}
      <section className="relative  overflow-hidden  2xl:pt-0" id="what-we-do">
				<div className="absolute bottom-0 right-0 w-full">
          <div className="mx-auto container--retina">
            <div className="w-1/3 ml-auto md:w-4/12 lg:w-3/12 2xl:w-2/12">
              <Shape 
                static={true}
                index={9}
                color="secondary"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="w-full md:w-2/3 lg:w-8/12">
            <h2 className="h1">What We Do</h2>
            <p className="text-lg lg:text-xl">With over 22 years of experience, we’re one of Nottingham’s longest standing and most reputable digital marketing companies. As a full-service agency, we have a wealth of knowledge and technical expertise and a proven track record of delivering results. Our ultimate aim is to ensure your business generates quality leads through a strategic and high-impact digital solution.</p>
          </div>

          <div className="relative z-10 mb-10 mr-4 bg-transparent md:mr-8">
            <div className="flex flex-wrap pt-16 pb-10 pr-12 md:-mx-8 md:pb-0 md:pt-20 lg:pt-24">
              {page.ourServices.map(({ title, model, slug, treeParent, heroExcerpt, icon }, index) => {
                // Generate the top level slug from the models apiKey
                let slugged = slugify(model.apiKey + 's',{
                  lower: true,
                }).replace(/[_]/g, '-');
                let trimmedHeroExcerpt = heroExcerpt.replace(/^(.{120}[^\s]*).*/, "$1...");

                return (
                  <div key={index} className="flex flex-wrap w-full mb-8 md:w-1/2 lg:w-1/4 md:mb-16 md:px-8 lg:mb-20">
                    { icon && (
                      // @TODO convert this to SVG component?
                      <div className="w-full">
                        <svg width="35" height="35" className="block mb-8 lg:mb-10">
                          <image href={icon.url} src={icon.url} width="35" height="35"/>
                        </svg>
                      </div>
                    )}

                    <Link to={treeParent ? `/${slugged}/${treeParent.slug}/${slug}` : `/${slugged}/${slug}`} className="block w-full mb-4 h3">{ title }</Link>

                    { trimmedHeroExcerpt && (
                      <div className="w-full mb-6 text-lg" dangerouslySetInnerHTML={{ __html: trimmedHeroExcerpt }}></div>
                    )}

                    <Link
                      // if it has a parent, pipe it into the link, if not leave it out 
                      to={treeParent ? `/${slugged}/${treeParent.slug}/${slug}` : `/${slugged}/${slug}`}
                      className="block w-full mt-auto text-lg link text-secondary font-display"
                    >Read more <span className={`ml-2 inline-block text-primary`}><Triangle size={`small`} /></span></Link>
                  </div>
                )
              })}
            </div>
            <div className="flex flex-wrap w-full pb-10 justi sm:-mx-2 md:pb-24 lg:pb-24 lg:mt-8">
              <div className="w-full mb-4 sm:w-auto sm:mb-0 sm:px-2">
                <Link to="/services/" className="block w-full btn btn--primary">Digital Services</Link>
              </div>
              <div className="w-full sm:w-auto sm:px-2">
                <Link to="/creative-services/" className="block w-full btn btn--primary">Creative Services</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ReferralConcreteGrantPage

export const query = graphql`
  query referralConcreteGrantPageQuery {
    page: datoCmsGrantForm {
      metaTags {
        title
        description
        twitterCard
        image {
          url
        }
      }
      title
      heroHeading
      heroBlurb
      ourServices {
        ... on DatoCmsService {
          id
          slug
          title
          heroExcerpt
          icon {
            url
          }
          treeParent {
            title
            slug
          }
          model {
            id
            apiKey
            name
          }
        }
        ... on DatoCmsCreativeService {
          id
          slug
          title
          heroExcerpt
          icon {
            url
          }
          treeParent {
            title
            slug
          }
          model {
            id
            apiKey
            name
          }
        }
      }
    }
  }
`
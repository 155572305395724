import React from "react"
import PropTypes from "prop-types"
import Shape from "../shape/shape"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Triangle from "../triangle/triangle"

const slugify = require("slugify");

const ourServices = ({ page }) => (
    <div className="relative z-10 mb-10 mr-4 bg-transparent md:mr-8 md:mb-10 lg:mb-12 xl:mb-12">
        <div className="flex flex-wrap pt-16 pb-10 pr-12 md:-mx-8 md:pb-0 md:pt-20 lg:pt-24">
            {page.ourServices.map(({ title, model, slug, treeParent, heroExcerpt, icon }, index) => {
            // Generate the top level slug from the models apiKey
            let slugged = slugify(model.apiKey + 's',{
                lower: true,
            }).replace(/[_]/g, '-');
            let trimmedHeroExcerpt = heroExcerpt.replace(/^(.{120}[^\s]*).*/, "$1...");

            return (
                
                <div key={index} className="flex flex-wrap w-full mb-8 md:w-1/2 lg:w-1/4 md:mb-16 md:px-8 lg:mb-20">
                    <p>{title}</p>
                { icon && (
                    // @TODO convert this to SVG component?
                    <div className="w-full">
                    <svg width="35" height="35" className="block mb-8 lg:mb-10">
                        <image href={icon.url} src={icon.url} width="35" height="35"/>
                    </svg>
                    </div>
                )}

                <Link to={treeParent ? `/${slugged}/${treeParent.slug}/${slug}` : `/${slugged}/${slug}`} className="block w-full mb-4 h3">{ title }</Link>

                { heroExcerpt && (
                    <div className="w-full mb-6 text-lg" dangerouslySetInnerHTML={{ __html: trimmedHeroExcerpt }}></div>
                )}

                <Link
                    // if it has a parent, pipe it into the link, if not leave it out 
                    to={treeParent ? `/${slugged}/${treeParent.slug}/${slug}` : `/${slugged}/${slug}`}
                    className="block w-full mt-auto text-lg link text-secondary font-display"
                >Read more <span className={`ml-2 inline-block text-primary border-gray-lighter border-1`}><Triangle size={`small`} /></span></Link>
                </div>
            )
            })}
        </div>
    </div>
)

export default ourServices